import React from "react";
import { DropdownOption } from "./DropdownOption";
export const SubFilterDropdown = (props) => {
    return (React.createElement("div", { "data-testid": "filter-dropdown__menu", className: `filter-dropdown__menu ${props.open ? "active" : ""}` },
        React.createElement("form", { action: "", className: "filter-js flex flex-col overflow-y-scroll max-h-72" },
            React.createElement("div", { className: "py-4 pl-4 pr-4 radio-buttons-js" },
                React.createElement("fieldset", { "aria-label": "dropdown options" },
                    React.createElement(DropdownOption, { termId: null, name: "View All", active: false, hierarchy: props.hierarchy, handleClick: () => props.clearDropdownHandler(props.hierarchy) }),
                    props.elements)))));
};
