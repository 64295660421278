//There is a large variety in how the legacy blog posts make use of the h1-h6 tags. Our blog is meant to have only two
// levels of heading, an h1 and an h2, so we're going to run this javascript to compress the headers.
//The rule is simple: the largest heading in use becomes the h1, and everything else becomes an h2
function changeElementTag(incumbent, replacementTag) {
    const newElement = document.createElement(replacementTag);
    // Copy the children
    while (incumbent.firstChild) {
        newElement.appendChild(incumbent.firstChild); // *Moves* the child
    }
    // Copy the attributes
    [...incumbent.attributes].forEach(attr => {
        newElement.setAttribute(attr.nodeName, attr.nodeValue);
    });
    // Replace it
    incumbent.parentNode.replaceChild(newElement, incumbent);
}
export function onDOMLoaded() {
    const blogWrapper = document.querySelector('article.blog-post');
    if (blogWrapper) {
        const headingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
        let headingsSeen = 0;
        const rewriteRule = [];
        headingLevels.forEach(headingTag => {
            const count = blogWrapper.querySelectorAll(headingTag).length;
            if (count > 0) {
                headingsSeen++;
                const assignedLevel = headingsSeen === 1 ? 'h1' : 'h2';
                rewriteRule.push(assignedLevel);
            }
            else {
                rewriteRule.push(null);
            }
        });
        if (rewriteRule.slice(2) !== ['', '', '', '']) {
            // If the last four levels of heading are unused, you know that they're using h1s and h2s in some combination, or
            // none at all! In that case, no further action is required.
            rewriteRule.forEach((replacementTag, index) => {
                if (replacementTag !== null) {
                    blogWrapper.querySelectorAll(`h${index + 1}`).forEach((incumbent) => changeElementTag(incumbent, replacementTag));
                }
            });
        }
    }
}
