export function onDOMLoaded() {
    const HAMBURGER_ANIMATION_DURATION_MS = 400;
    // TODO: 800 / 2 just so happens to work with this easing function,
    //  but it may be possible that the 50% keyframe will fall on
    //  some other timestamp. You can do something when an
    //  animationends, but no way to observe the running function.
    //  I think if you can figure out the graph of cubic bezier
    //  that creates the ease-in function, this should be calculable.
    // TODO: It's possible to catch this in a weird spot,
    //  so I would probably cancel all clicks while it's mid animation.
    const navbar = document.getElementById('nav-bar');
    const hamburger = document.getElementById('nav-opener--js');
    const modal = document.getElementById('Navigation');
    const hamburgerBars = hamburger.querySelectorAll('.hamburger-bar');
    hamburgerBars.forEach(x => {
        x.style.animationDuration = `${HAMBURGER_ANIMATION_DURATION_MS}ms`;
    });
    const spacer = document.getElementById('nav-spacer--js');
    const navIsTransparent = navbar.classList.contains('nav-bar--transparent--light');
    let iterationCounter = 1;
    hamburger.addEventListener('animationend', (event) => {
        hamburger.classList.remove('animating');
        const target = event.target;
        target.style.animationIterationCount = iterationCounter.toString();
    });
    hamburger.addEventListener('click', function () {
        if (navbar.classList.contains('nav-bar--open')) {
            removeOverlay(navIsTransparent);
        }
        else {
            showOverlay(navIsTransparent);
        }
    });
    function showOverlay(navIsTransparent) {
        toggleOverlay();
        document.addEventListener('keydown', trapFocus);
        if (!navIsTransparent) {
            spacer.classList.add('block');
            spacer.classList.remove('hidden');
        }
    }
    function removeOverlay(navIsTransparent) {
        toggleOverlay();
        document.removeEventListener('keydown', trapFocus);
        if (!navIsTransparent) {
            spacer.classList.remove('block');
            spacer.classList.add('hidden');
        }
    }
    function toggleOverlay() {
        ++iterationCounter;
        hamburger.classList.add('animating');
        setAriaExpanded();
        toggleClasses();
        toggleModal();
    }
    function setAriaExpanded() {
        const hamburgerid = (hamburger.getAttribute('aria-expanded') === 'true');
        const newhamburgerid = (!hamburgerid).toString();
        hamburger.setAttribute('aria-expanded', newhamburgerid);
    }
    function toggleClasses() {
        const bc = document.body.classList;
        const nc = navbar.classList;
        const menustate = bc.contains('showing-nav');
        if (menustate) {
            bc.remove('showing-nav');
            nc.remove('nav-bar--open');
        }
        else {
            bc.add('showing-nav');
            nc.add('nav-bar--open');
        }
    }
    function toggleModal() {
        if (modal.hasAttribute('role')) {
            modal.removeAttribute('role');
            modal.setAttribute('aria-modal', 'false');
            modal.removeAttribute('aria-label');
        }
        else {
            modal.setAttribute('role', 'dialog');
            modal.setAttribute('aria-modal', 'true');
            modal.setAttribute('aria-label', 'Navigation');
        }
    }
    function trapFocus(event) {
        if (event.key === "Escape") {
            removeOverlay(navIsTransparent);
            hamburger.focus();
        }
        if (event.key !== "Tab")
            return;
        const focusable = navbar.querySelectorAll('a[href]:not([disabled]), button:not([disabled])');
        const closemenubutton = focusable[1];
        const lastmenuitem = focusable[focusable.length - 1];
        if (event.shiftKey) {
            if (document.activeElement === closemenubutton) {
                lastmenuitem.focus();
                event.preventDefault();
            }
        }
        else {
            if (document.activeElement === lastmenuitem) {
                closemenubutton.focus();
                event.preventDefault();
            }
        }
    }
}
