export class Query {
    constructor(filters, options) {
        this.filters = filters;
        this.options = options;
    }
    body() {
        return this.buildBody(this.filters, this.options);
    }
    buildBody(filters, options) {
        var _a, _b;
        const relatedToCategories = this.formatQueries(filters);
        const bodyParams = {
            limit: (_a = options.limit) !== null && _a !== void 0 ? _a : 0,
            offset: (_b = options.offset) !== null && _b !== void 0 ? _b : 0,
            relatedToCategories: relatedToCategories
        };
        return JSON.stringify(bodyParams);
    }
    formatQueries(groups) {
        const groupsWithIds = groups.filter(x => x.ids.length > 0);
        if (groupsWithIds.length === 0) {
            return null;
        }
        let craftCriteriaArray = ["and"];
        groupsWithIds.forEach(group => {
            craftCriteriaArray.push(["or", ...group.ids]);
        });
        return craftCriteriaArray;
    }
}
