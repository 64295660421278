import React, { useEffect, useRef, useState } from 'react';
import { DropdownOption } from './DropdownOption';
import { SubFilterDropdown } from './SubFilterDropdown';
export const SubFilter = (props) => {
    const [dropdownOpen, updateDropdown] = useState(false);
    const toggleDropdown = () => {
        updateDropdown(!dropdownOpen);
    };
    const dropdownRef = useRef(null);
    useEffect(() => {
        document.addEventListener("click", function (event) {
            const target = event.target;
            const nearestDropdown = target.closest(".filter-dropdown-js");
            if (nearestDropdown === null || nearestDropdown !== dropdownRef.current) {
                updateDropdown(false);
            }
        });
    }, []);
    const dropdownOptions = props.data.map(x => (React.createElement(DropdownOption, { key: `${x.termId}-${x.hierarchy}`, termId: x.termId, name: x.name, hierarchy: x.hierarchy, active: x.active, handleClick: props.handleClick })));
    return (React.createElement("div", { ref: dropdownRef, className: "mr-16 filter-dropdown filter-dropdown-js cursor-pointer" },
        React.createElement("div", { className: "filter-dropdown__header w-max", onClick: () => toggleDropdown() },
            React.createElement("span", { className: "headline small2 mr-2" }, props.label),
            " ",
            React.createElement("img", { src: `/assets/images/down-arrow${props.color == "white" ? "-white" : ""}.svg`, className: "inline align-baseline", alt: `Open ${props.label} picker` })),
        React.createElement(SubFilterDropdown, { open: dropdownOpen, elements: dropdownOptions, hierarchy: props.hierarchy, clearDropdownHandler: props.clearDropdownHandler })));
};
