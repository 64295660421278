import { onDOMLoaded as radioButtonsOnLoad } from './radio-buttons';
import { onDOMLoaded as navOnLoad } from './nav-bar';
import { setUpAccordions } from "./accordions";
import { setUpCarousels } from "./carousels";
import animateHero from "./heroes";
import { onDOMLoaded as insightsFilterOnLoad } from './filtering/insights-filter';
import { onDOMLoaded as caseStudiesFilterOnLoad } from './filtering/case-studies-filter';
import { onDOMLoaded as rewriteBlogHeadings } from './rewrite-blog-headings';
import setupModals from "./modal";
// There are many scripts in this repo that want to do something when
// all of the domContent has loaded, so we'll handle all of those here.
// Scripts that need this functionality are expected to export an
// onDOMLoaded function.
function attachAllDOMLoadedListeners() {
    // TODO: May want to pull this earlier, maybe into the critical path, in case it doesn't
    //  fire quickly enough to allow us to hide stuff that should be hidden on load, but can't
    //  be done thru the templates, like the hero headline text animations.
    // This will remove the `no-js` tag which sits on the html element by
    // default. Allows us to target browsers with javascript disabled.
    const htmlEl = document.querySelector('html');
    htmlEl.classList.remove('no-js');
    htmlEl.classList.add('js');
    // These add elements to the DOM, so you want to run these before the others.
    radioButtonsOnLoad();
    /////////
    // These can run anywhere, but make sure each function takes responsibility for checking that the DOM has whatever it needs to work.
    navOnLoad();
    setUpAccordions();
    setUpCarousels();
    animateHero();
    insightsFilterOnLoad();
    caseStudiesFilterOnLoad();
    rewriteBlogHeadings();
    setupModals();
}
if (document.readyState === 'loading') { // Loading hasn't finished yet
    document.addEventListener('DOMContentLoaded', attachAllDOMLoadedListeners);
}
else { // `DOMContentLoaded` has already fired
    attachAllDOMLoadedListeners();
}
