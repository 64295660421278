import React from "react";
import { createRoot } from "react-dom/client";
import { MessageArea } from "../components/filtering/MessageArea";
import { LoadMoreButton } from "../components/filtering/LoadMoreButton";
import { FilterFetch } from "./FilterFetch";
import { AjaxClient } from "../AjaxClient";
import { BreadCrumbsContainer } from "../components/filtering/BreadCrumbsContainer";
import { BreadCrumb } from "../components/filtering/BreadCrumb";
import { SubFilter } from "../components/filtering/SubFilter";
import { LoadingSpinner } from "../components/filtering/LoadingSpinner";
const POST_TYPE_CRAFT_HANDLE = "insightsType";
const POST_CATEGORY_CRAFT_HANDLE = "insights";
const POST_TAG_CRAFT_HANDLE = "insightsTags";
const AJAX_ENDPOINT = "/insights/grid-layout";
function prepState(objects, hierarchy) {
    return objects.map(x => {
        return {
            active: false,
            termId: x.term_id,
            name: x.name,
            hierarchy
        };
    });
}
function InsightsFilterBar(props) {
    const breadcrumbs = [
        ...props.categories,
        ...props.postTypes,
        ...props.postTags
    ].map(x => (React.createElement(BreadCrumb, { key: `${x.termId}-${x.name}`, termId: x.termId, name: x.name, active: x.active, handleClick: props.updateTerm, hierarchy: x.hierarchy })));
    const subfilterProps = [
        { label: "CATEGORIES", data: props.categories, hierarchy: "category" },
        {
            label: "POST TYPES",
            data: props.postTypes,
            hierarchy: "postType"
        },
        {
            label: "POST TAGS",
            data: props.postTags,
            hierarchy: "postTag"
        }
    ];
    const subfilters = subfilterProps.map(x => (React.createElement(SubFilter, { key: x.label, label: x.label, data: x.data, hierarchy: x.hierarchy, handleClick: props.updateTerm, clearDropdownHandler: props.clearDropdown, color: props.filterColor })));
    return (React.createElement("div", { id: "blog-card-filter-bar", className: "blog-card-filter-bar border-bottom pb-7 mb-20 flex flex-wrap items-baseline relative "
            + (props.filterColor === "white" ? "border-white" : "border-black") },
        subfilters,
        React.createElement(BreadCrumbsContainer, { breadcrumbs: breadcrumbs }),
        React.createElement(MessageArea, { pending: props.pending, postCount: props.postCount })));
}
export class InsightsIndex extends React.Component {
    constructor(props) {
        super(props);
        this.blogLayoutRef = React.createRef();
        // Saving initial state so it's easy to wipe out a whole dropdown when you click "View All"
        this.initialState = {
            category: prepState(this.props.categoryData, "category"),
            postType: prepState(this.props.postTypeData, "postType"),
            postTag: prepState(this.props.postTagsData, "postTag"),
            postCount: this.props.initialPostCount,
            pending: false,
            markup: this.props.initialMarkup,
            filterFetch: this.props.filterFetch
        };
        this.state = Object.assign({}, this.initialState);
        this.updateSingleTag = this.updateSingleTag.bind(this);
        this.clearDropdown = this.clearDropdown.bind(this);
        this.fetchFilteredPosts = this.fetchFilteredPosts.bind(this);
        this.loadMorePosts = this.loadMorePosts.bind(this);
    }
    updateSingleTag(termId, active, hierarchy) {
        const newState = Object.assign({}, this.state);
        if (hierarchy in newState) {
            newState[hierarchy] = newState[hierarchy].map((x) => {
                if (x.termId === termId) {
                    return Object.assign(Object.assign({}, x), { active: !x.active });
                }
                else {
                    return x;
                }
            });
            this.setState(newState);
        }
    }
    clearDropdown(hierarchy) {
        const newState = Object.assign({}, this.state);
        newState[hierarchy] = this.initialState[hierarchy];
        this.setState(newState);
    }
    buildFilters(filter, selection) {
        const activeSelection = selection
            .filter(x => x.active)
            .map(x => x.termId);
        return { group: filter, ids: activeSelection };
    }
    fetchFilteredPosts(limit = 9, offset = 0, method = "overwrite") {
        this.setState(Object.assign(Object.assign({}, this.state), { pending: true }));
        const activeCategories = this.buildFilters(POST_CATEGORY_CRAFT_HANDLE, this.state.category);
        const activePostTypes = this.buildFilters(POST_TYPE_CRAFT_HANDLE, this.state.postType);
        const activePostTags = this.buildFilters(POST_TAG_CRAFT_HANDLE, this.state.postTag);
        const filters = [
            activeCategories,
            activePostTypes,
            activePostTags
        ];
        const options = { limit: limit, offset: offset, method: method };
        this.state.filterFetch.fetchPosts(filters, options, AJAX_ENDPOINT).then((response) => {
            let postCount;
            const newState = Object.assign({}, this.state);
            const blogWrapper = this.blogLayoutRef.current;
            if (blogWrapper) {
                const markup = response.markup ? response.markup : "";
                if (method === "overwrite") {
                    newState.markup = markup;
                    postCount = response.count;
                }
                else {
                    newState.markup = this.state.markup + markup;
                    postCount = this.state.postCount + response.count;
                }
                newState.pending = false;
            }
            const morePosts = postCount < response.total && response.total != 0;
            this.setState(newState, () => {
                this.setState({ postCount: postCount, morePosts: morePosts });
            });
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (!(this.state.category === prevState.category &&
            this.state.postType === prevState.postType &&
            this.state.postTag === prevState.postTag)) {
            this.fetchFilteredPosts();
        }
    }
    loadMorePosts(event) {
        event.preventDefault();
        this.fetchFilteredPosts(9, this.state.postCount, "append");
    }
    render() {
        const loadingStyles = this.state.pending ? " opacity-20" : "";
        return (React.createElement("div", null,
            React.createElement(InsightsFilterBar, { filterColor: this.props.filterColor, categories: this.state.category, postTypes: this.state.postType, postTags: this.state.postTag, updateTerm: this.updateSingleTag, clearDropdown: this.clearDropdown, postCount: this.state.postCount, pending: this.state.pending }),
            React.createElement("div", { className: `insights-grid-container` + loadingStyles, ref: this.blogLayoutRef, dangerouslySetInnerHTML: { __html: this.state.markup } }),
            this.state.pending ?
                React.createElement(LoadingSpinner, null)
                :
                    React.createElement(LoadMoreButton, { clickHandler: this.loadMorePosts })));
    }
}
export function onDOMLoaded() {
    const blogRoot = document.getElementById("insights-filterable-area-js");
    const blogWrapper = document.getElementById("blog-posts-wrapper");
    const blogList = document.getElementById("blog-posts-list");
    // @ts-ignore
    let data = window.insights;
    if (blogRoot && blogWrapper && data) {
        const initialMarkup = blogWrapper.innerHTML;
        const postCount = blogList.childElementCount;
        const filterFetch = new FilterFetch(new AjaxClient());
        const { color: filterColor, post_categories: categoryData, post_types: postTypeData, post_tags: postTagsData } = data.filters;
        const root = createRoot(blogRoot);
        root.render(React.createElement(InsightsIndex, { filterColor: filterColor, categoryData: categoryData, postTypeData: postTypeData, postTagsData: postTagsData, initialMarkup: initialMarkup, initialPostCount: postCount, filterFetch: filterFetch }));
    }
}
