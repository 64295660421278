var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Methods } from "../AjaxClient";
import { Query } from "./Query";
export class FilterFetch {
    constructor(client) {
        this.client = client;
    }
    fetchPosts(filters, options, endpoint) {
        return __awaiter(this, void 0, void 0, function* () {
            const query = new Query(filters, options);
            return this.client.makeRequest(Methods.POST, endpoint, { "Content-Type": "application/json" }, query.body()).then((response) => __awaiter(this, void 0, void 0, function* () {
                if (response.status === 200) {
                    return yield this.handleSuccessfulResponse(response);
                }
                else {
                    return this.handleErrorResponse(response);
                }
            }))
                .catch(error => {
                console.error(error);
                return { count: 0, total: 0 };
            });
        });
    }
    handleErrorResponse(response) {
        response.text().then((error) => console.error(error));
        return { markup: "", total: 0, count: 0 };
    }
    handleSuccessfulResponse(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const count = Number(response.headers.get("Post-Count"));
            const total = Number(response.headers.get("Entry-Total"));
            const responseText = yield response.text();
            const text = responseText.trim();
            return { markup: text, total: total, count: count };
        });
    }
}
