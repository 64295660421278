function setupModals() {
    const modals = document.querySelectorAll('.has-modal');
    modals.forEach(block => {
        const opener = block.querySelector('.modal-opener');
        const dialog = block.querySelector('.modal-dialog');
        const closer = block.querySelector('.modal-closer');
        opener.addEventListener('click', () => {
            dialog.showModal();
        });
        closer.addEventListener('click', () => {
            dialog.close();
        });
        dialog.addEventListener('click', (e) => {
            if (e.target == dialog) {
                dialog.close();
            }
        });
    });
}
export default setupModals;
