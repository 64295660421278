export function onDOMLoaded() {
  document.querySelectorAll('.radio-buttons-js').forEach(form => {
    form.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      const svgWrapper = document.createElement("div");
      // Inherit all classes from the input, which will be hidden and
      // unavailable for click events
      svgWrapper.classList = checkbox.classList;
      svgWrapper.classList.add('radio-button');
      svgWrapper.innerHTML = `<svg style="" width="1.0em" viewBox="0 0 20 20">
                        <rect width="20" height="20" stroke-width="2" fill="none"/>
                      </svg>`
      checkbox.insertAdjacentElement('afterend', svgWrapper)
      svgWrapper.addEventListener('click', function(e) {
        // NOTE: Though these radio buttons look like radio buttons, they
        // frequently are treated like checkboxes. Which is to say, you
        // can have multiple buttons checked. If you don't want that
        // behavior, and need the clicking of one to switch off all the
        // others, tag the checkbox with an "data-exclusive='true'" attribute.
        const fieldset = checkbox.closest('fieldset')
        if (fieldset && checkbox.dataset.exclusiveCheck === 'true') {
          fieldset.querySelectorAll('input[type="checkbox"]').forEach(el => el.checked = false)
        }
        checkbox.checked = !checkbox.checked;
      })
    })
  })
}