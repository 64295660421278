import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';
export function setUpCarousels() {
    document.querySelectorAll('.glide').forEach(div => {
        div.querySelectorAll('.glide__slide').forEach(x => x.classList.remove('hidden'));
        // Using ts-ignore because types on GlideJS are wrong,
        // you can pass an HTMLElement in here and not just a selector string.
        // @ts-ignore
        new Glide(div, {
            type: 'carousel'
        }).mount();
    });
}
