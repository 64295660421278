import React from "react";
import { LoadingSpinner } from "./LoadingSpinner";
export const MessageArea = (props) => {
    const failedToFindPosts = !props.pending && props.postCount === 0;
    return (React.createElement("div", { className: "w-full absolute z-10 -bottom-96" },
        failedToFindPosts && (React.createElement("div", null,
            React.createElement("div", { className: "col-span-12 txt-2xl absolute bottom-20 lg:bottom-0" }, "No posts found."),
            React.createElement("div", null))),
        props.pending && (React.createElement(LoadingSpinner, { style: "absolute -bottom-16" }))));
};
