// if reusing in another context, remember this
// relies on some declarations in '../styles/accordions.css';
export function setUpStandardAccordions() {
    document.querySelectorAll(".accordion-js").forEach(accordion => {
        const opener = accordion.querySelector(".accordion-opener-js");
        const drawerContent = accordion.querySelector(".accordion-content-js");
        if (drawerContent && opener) {
            if (drawerContent.classList.contains("active")) {
                openDrawer();
            }
            function handleAccordion() {
                drawerContent.classList.toggle("active");
                if (drawerContent.classList.contains("active")) {
                    openDrawer();
                }
                else {
                    drawerContent.style.height = "0";
                    opener.querySelector("span").innerText = "More";
                    opener.querySelector("svg").style.transform = "rotate(0deg)";
                }
            }
            function openDrawer() {
                drawerContent.style.height = `${drawerContent.scrollHeight}px`;
                opener.querySelector("span").innerText = "Less";
                opener.querySelector("svg").style.transform = "rotate(180deg)";
            }
            opener.addEventListener("click", handleAccordion);
        }
    });
}
function closeAccordion(accordion) {
    const opener = accordion.querySelector(".service-accordion-opener-js");
    const drawerContent = accordion.querySelector(".service-accordion-content-js");
    accordion.classList.remove("active");
    drawerContent.classList.remove("active");
    drawerContent.style.height = "0";
    opener.querySelector("span").innerText = "More";
    opener.querySelector("svg").style.transform = "rotate(0deg)";
}
export function setUpServiceAccordions() {
    const accordionWrap = document.querySelector(".service-accordion-wrap-js");
    const allAccordions = document.querySelectorAll(".service-accordion-js");
    function handleAccordion(accordion) {
        const opener = accordion.querySelector(".service-accordion-opener-js");
        const drawerContent = accordion.querySelector(".service-accordion-content-js");
        const drawerIsOpen = drawerContent.classList.contains("active");
        allAccordions.forEach((x) => closeAccordion(x));
        if (drawerIsOpen) {
            closeAccordion(accordion);
        }
        else {
            drawerContent.style.height = `${drawerContent.scrollHeight}px`;
            opener.querySelector("span").innerText = "Less";
            opener.querySelector("svg").style.transform = "rotate(180deg)";
            accordionWrap.classList.add("active");
            accordion.classList.add("active");
            drawerContent.classList.add("active");
        }
        const noDrawersActive = [...allAccordions].every(x => !x.classList.contains("active"));
        if (noDrawersActive) {
            accordionWrap.classList.remove("active");
        }
    }
    allAccordions.forEach((accordion) => {
        const opener = accordion.querySelector(".service-accordion-opener-js");
        const headline = accordion.querySelector(".service-accordion-headline");
        opener.addEventListener("click", () => handleAccordion(accordion));
        headline.addEventListener("click", () => handleAccordion(accordion));
    });
}
export function setUpAccordions() {
    setUpStandardAccordions();
    setUpServiceAccordions();
}
