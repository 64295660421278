const FPS = 16;
const MS_PER_FRAME = 1000 / FPS;
const MS_STAGGER_TIME_PER_WORD = 100;
const MEAN_LETTER_TRANSFORMS = 6;
const LETTER_TRANSFORMS_STD_DEV = 4;
const SPARK_PROBABILITY = 0.0;
// To ease out of the animation, we'll pay attention to a critical threshold and start to slow down once we break it.
const TRICKLE_THRESHOLD = 20;
// Tack on this many MS for every number you go below the "TRICKLE_THRESHOLD"
const TRICKLE_RATE = 5;
function checkPrefersReducedMotion() {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    return mediaQuery.matches;
}
function shuffle(arr) {
    // Start from the last element and swap
    // one by one. We don't need to run for
    // the first element that's why i > 0
    for (let i = arr.length - 1; i > 0; i--) {
        // Pick a random index from 0 to i inclusive
        let j = Math.floor(Math.random() * (i + 1));
        // Swap arr[i] with the element
        // at random index
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
}
function boxMullerTransform() {
    const u1 = Math.random();
    const u2 = Math.random();
    const z0 = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2);
    const z1 = Math.sqrt(-2.0 * Math.log(u1)) * Math.sin(2.0 * Math.PI * u2);
    return { z0, z1 };
}
function getNormallyDistributedRandomNumber(mean, stddev) {
    const { z0, z1 } = boxMullerTransform();
    return z0 * stddev + mean;
}
function locateSpark(el, refString) {
    const prevText = el.previousSibling.textContent;
    const thisText = el.textContent.trim();
    const nextText = el.nextSibling.textContent;
    const pattern = `${prevText}${thisText}${nextText}`;
    const index = refString.search(pattern) + prevText.length;
    return { index, classTokens: el.classList };
}
function animateHero() {
    const firstSpark = document.querySelector('#hero__headline .font-sparks');
    if (checkPrefersReducedMotion()) {
        // Don't screw with the DOM if the user has requested reduced motion
        return null;
    }
    if (!document.documentElement.classList.contains('js') || firstSpark === null) {
        // Don't screw with the DOM if javascript isn't enabled
        return null;
    }
    //  Grab the text wrapper by looking for a spark and grabbing the nearest parent.
    const hero = firstSpark.parentElement;
    const heroText = hero.innerText;
    hero.setAttribute('aria-hidden', 'true');
    // TODO: Make sure the headline doesn't flash in before this javascript gets a hold of it.
    // hero.style.opacity = '0';
    //  Split content by whitespaces, and keep in mind that the sparks are interspersed so you need to do extra work to stick words together
    const plainString = hero.textContent;
    const sparkData = [...hero.querySelectorAll('.font-sparks')]
        .map((x) => locateSpark(x, plainString));
    // Hide the content
    hero.style.height = `${hero.scrollHeight}px`;
    hero.innerHTML = '';
    const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
    const wordMatches = plainString.matchAll(/(\S+)/g);
    for (const wordMatch of wordMatches) {
        const word = wordMatch[0];
        const wordSpan = document.createElement('span');
        wordSpan.classList.add('hero-word-js');
        for (let i = 0; i < word.length; i++) {
            shuffle(alphabet);
            const letter = document.createElement('span');
            let letterContent = "\u00A0";
            const matchedSpark = sparkData.find(x => x.index === wordMatch.index + i);
            if (matchedSpark) {
                // letter.dataset['finalClass'] = [...matchedSpark.classTokens].join(' ');
                letter.setAttribute('class', [...matchedSpark.classTokens].join(' '));
                letterContent = word[i];
            }
            else {
                letter.classList.add('opacity-25');
                const gaussianNumber = Math.round(getNormallyDistributedRandomNumber(MEAN_LETTER_TRANSFORMS, LETTER_TRANSFORMS_STD_DEV));
                const numOfTransmutes = gaussianNumber < 0 ? 1 : gaussianNumber;
                letter.dataset['transmuteSequence'] = alphabet.slice(0, numOfTransmutes).join('') + word[i];
                letter.dataset['transmuteCountdown'] = String(numOfTransmutes + 1);
            }
            letter.appendChild(document.createTextNode(letterContent));
            wordSpan.appendChild(letter);
        }
        hero.appendChild(wordSpan);
        if ((wordMatch.index + word.length) < plainString.length) {
            hero.appendChild(document.createTextNode(' '));
        }
    }
    const letters = hero.querySelectorAll('span[data-transmute-countdown]');
    const palette = ['text-spectrum-red', 'text-spectrum-orange', 'text-spectrum-yellow', 'text-spectrum-green',
        'text-spectrum-cyan', 'text-spectrum-blue', 'text-spectrum-indigo', 'text-spectrum-violet'];
    let start;
    let previousTimeStamp;
    function step(timestamp) {
        if (start === undefined) {
            start = timestamp;
        }
        const elapsed = timestamp - start;
        let totalCountdown = 0;
        if (previousTimeStamp !== timestamp) {
            // Each letter gets a string of gibberish + its correct final letter. It also maintains an index to track
            // its position within the gibberish. With each iteration, we decrement it, and when it hits zero we make sure
            // it gets its final css classes (in case it's a spark).
            letters.forEach((letter) => {
                const { transmuteSequence } = letter.dataset;
                let transmuteCountdown = Number(letter.dataset.transmuteCountdown);
                if (transmuteCountdown > 0) {
                    if (Math.random() < SPARK_PROBABILITY) {
                        const colorClass = palette[Math.floor(Math.random() * palette.length)];
                        letter.classList.add('font-sparks', colorClass);
                    }
                    letter.textContent = transmuteSequence[transmuteSequence.length - transmuteCountdown];
                    transmuteCountdown--;
                    letter.dataset['transmuteCountdown'] = String(transmuteCountdown);
                    if (transmuteCountdown === 0) {
                        const finalClass = letter.dataset.finalClass || '';
                        letter.setAttribute('class', finalClass);
                    }
                }
                totalCountdown += transmuteCountdown;
            });
            if (totalCountdown > 0) {
                // To ease out of the animation, I want to start slowing down the animation
                // the fewer transmutations there are to perform. I think we could put a delay attribute on letters, and then
                // only update it if the elapsed time is greater than that.
                // (So you could imagine the delay would start off at zero, which means this letter updates
                // at every opportunity, and then would climb as it got closer to the end of its sequence)
                let frameDelay = MS_PER_FRAME;
                if (totalCountdown < TRICKLE_THRESHOLD) {
                    frameDelay = MS_PER_FRAME + (TRICKLE_RATE * (TRICKLE_THRESHOLD - totalCountdown));
                }
                setTimeout(() => {
                    previousTimeStamp = timestamp;
                    window.requestAnimationFrame(step);
                }, frameDelay);
            }
        }
    }
    window.requestAnimationFrame(step);
    //  Reset the height now that the animation is finished.
    hero.style.height = null;
    const srOnlySpan = document.createElement('span');
    srOnlySpan.setAttribute('class', 'sr-only');
    srOnlySpan.innerHTML = heroText;
    const heroParent = hero.parentElement;
    heroParent.appendChild(srOnlySpan);
}
function animateHero_WHOLEWORDS() {
    if (!document.documentElement.classList.contains('js')) {
        // Don't screw with the DOM if javascript isn't enabled
        return null;
    }
    const heroBlock = document.getElementById('hero__headline');
    //  Grab the text wrapper by looking for a spark and grabbing the nearest parent.
    const hero = heroBlock.querySelector('.font-sparks').parentElement;
    //  Split content by whitespaces, and keep in mind that the sparks are interspersed so you need to do extra work to stick words together
    const plainString = hero.innerText;
    const sparkData = [...hero.querySelectorAll('.font-sparks')]
        .map((x) => locateSpark(x, plainString));
    // Hide the content
    hero.style.height = `${hero.scrollHeight}px`;
    hero.innerHTML = '';
    const wordMatches = plainString.matchAll(/(\S+)/g);
    for (const wordMatch of wordMatches) {
        const word = wordMatch[0];
        const wordSpan = document.createElement('span');
        wordSpan.classList.add('hero-word-js');
        for (let i = 0; i < word.length; i++) {
            const letter = document.createElement('span');
            let letterContent = "\u00A0";
            const matchedSpark = sparkData.find(x => x.index === wordMatch.index + i);
            if (matchedSpark) {
                letter.classList.add(...matchedSpark.classTokens);
                letterContent = word[i];
            }
            else {
                letter.dataset['content'] = word[i];
            }
            letter.appendChild(document.createTextNode(letterContent));
            wordSpan.appendChild(letter);
        }
        hero.appendChild(wordSpan);
        if ((wordMatch.index + word.length) < plainString.length) {
            hero.appendChild(document.createTextNode(' '));
        }
    }
    //  Randomize the words array, then pop them in.
    const heroWords = [...document.querySelectorAll('.hero-word-js')];
    shuffle(heroWords);
    let countdown = heroWords.length - 1;
    let start;
    let previousTimeStamp;
    function step(timestamp) {
        if (start === undefined) {
            start = timestamp;
        }
        const elapsed = timestamp - start;
        if (previousTimeStamp !== timestamp) {
            const word = heroWords[countdown];
            const letters = word.querySelectorAll('span[data-content]');
            letters.forEach((letter) => {
                letter.textContent = letter.dataset.content;
            });
            countdown--;
            if (countdown >= 0) {
                setTimeout(() => {
                    previousTimeStamp = timestamp;
                    window.requestAnimationFrame(step);
                }, MS_STAGGER_TIME_PER_WORD);
            }
        }
    }
    window.requestAnimationFrame(step);
    //  Reset the height now that the animation is finished.
    hero.style.height = null;
}
export default animateHero;
